import React, {Component} from "react";
import {Container, Segment} from "semantic-ui-react";

export class Footer extends Component {
    render() {
        return (
            <div>
                <Segment footer vertical inverted>
                    <Container>
                    </Container>
                </Segment>
            </div>);
    }
}