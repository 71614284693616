import React, {Component} from 'react';
import './App.scss';
import NavBar from "./NavBar";
// First we import some modules...
import {BrowserRouter as Router, Route} from "react-router-dom";
import Main from "./Main";
import About from "./About";
import {Footer} from "./Footer";


class App extends Component {
    render() {
        return (
            <Router>
                <div className='App'>
                    <NavBar/>
                    <div className='content'>
                        <Route exact path="/" component={Main}/>
                        <Route path="/about" component={About}/>
                    </div>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

export default App;
