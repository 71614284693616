import React from "react";
import {Container, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";


const NavBar = () => {
    return (
        <Menu fixed='top' inverted>
            <Container>
                <Menu.Item as='a' header>
                    Adam Franks
                </Menu.Item>
                <Menu.Item as={Link} to="/">Home</Menu.Item>
                <Menu.Item as={Link} to="/about">About</Menu.Item>
            </Container>
        </Menu>
    );
}

export default NavBar;