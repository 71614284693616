import {Container, Header, Message} from "semantic-ui-react";
import React from "react";

const TextDisplay = (props) => {
    return (
        <Container text className="main-container">
            <Header>{props.header}</Header>
            <Message header={props.title}
                     content={props.text}/>
        </Container>
    )
};

export default TextDisplay;